.ContenedorDescargas {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    min-height: 600px;
}
.ContenedorDescargas h1 {
    font-family: "TitilliumWebBold";
    text-align: justify;
}
.ContenedorDescargas p {
    font-family: "SourceSans3";
    text-align: justify;
    color: #666;
    margin-bottom: 0px;
}
.contenedorArchivos {
    display: flex;
    justify-content: space-between;
}
.linkDescarga {
    color: #199dec !important;
    cursor: pointer;
}
.linkDescarga:hover{
    text-decoration: underline;
}
.ContenedorDescargas a {
    font-family: "SourceSans3";
    background-color: black;
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: left;
    cursor: pointer;
    font-size: medium;
    transition: background-color 0.2s ease-in-out;
    text-decoration: none;
    margin-bottom: 50px;
    width: fit-content;
}

.ContenedorDescargas a:hover {
    background-color: #199dec;
}