.ContenedorPerfil {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
}
.ContenedorPerfil h3 {
    font-family: "TitilliumWebBold";
    text-align: justify;

}
.ContenedorPerfil p {
    font-family: "SourceSans3";
    text-align: justify;
    color: #666;
}
.ContenedorPerfil li {
    font-family: "SourceSans3";
    text-align: justify;
    color: #666;
}
.ContenedorPerfil {
    text-align: center !important;
}
.planEstudios {
    margin-bottom: 50px;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.planEstudios a {
    font-family: "SourceSans3";
    background-color: black;
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: left;
    cursor: pointer;
    font-size: medium;
    transition: background-color 0.2s ease-in-out;
    text-decoration: none;
    margin-bottom: 50px;
    width: fit-content;
}
.planEstudios a:hover {
    background-color: #199dec;
}
.planEstudios button {
    font-family: "SourceSans3";
    background-color: black;
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: left;
    cursor: pointer;
    font-size: medium;
    transition: background-color 0.2s ease-in-out;
    text-decoration: none;
    width: fit-content;
}
.planEstudios button:hover {
    background-color: #199dec;
}