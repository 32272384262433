.ContenedorInstrucciones {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
}
.ContenedorInstrucciones h1 {
    font-family: "TitilliumWebBold";
    text-align: justify;

}
.ContenedorInstrucciones h4 {
    font-family: "TitilliumWebBold";
    text-align: justify;
    color: #666;

}
.ContenedorInstrucciones p {
    font-family: "SourceSans3";
    text-align: justify;
    color: #666;
    white-space: pre-wrap;
}
.ContenedorInstrucciones li {
    font-family: "SourceSans3";
    text-align: justify;
    color: #666;
}
.contenedorLogoTexto {
    overflow: hidden;
}
.contenedorLogoTexto img {
    float: left;
    margin-right: 25px;
    max-width: 200px;
    height: auto;
}
.contenedorLogoTexto p {
    margin: 0px;
}