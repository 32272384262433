.Datos {
    margin-bottom: 50px;
}
.tituloDatos {
    font-family: "TitilliumWebBold";
    font-size: 18px;
    font-weight: 800;
    text-align: left;
    border-top: 3px solid black;
    border-bottom: 1px solid rgb(184, 184, 184);
    padding: 15px;
}
.contenedorImagenDatos {
    display: flex;
    width: 100%;
    background-color: #3F6EA6;
}
.contenedorImagen {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.contenedorImagen img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.contenedorDatos {
    width: 60%;
}
.contenedorDatos h1{
    font-family: "TitilliumWebBold";
    font-size: 40px;
    color: white;
    margin-bottom: 0px;
    text-align: center;
}
.contenedorDatos h2{
    font-family: "TitilliumWebBold";
    font-size: 45px;
    color: white;
    margin: 0;
}
.contenedorDatos p{
    font-family: "TitilliumWeb";
    font-size: 14px;
    color: white;
    margin: 0;
}

.contenedorDatosIndividuales {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 40px;
    padding: 40px 20px;
}
.dato {
    display: flex;
    flex-direction: row;
    flex: 1 1 39%;
    height: 100px;
    text-align: left;
}
.logoDato {
    display: flex;
    max-width: 75px;
    max-height: 75px;
    border: 1px solid rgba(255, 255, 255, 0.158);
    margin-right: 20px;
    padding: 30px;
}

.contenedorTextoDatos {
    display: flex;
    flex-direction: column;
}