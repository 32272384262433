.ContenedorNosotros {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
}
.ContenedorNosotros h1 {
    font-family: "TitilliumWebBold";
    text-align: justify;

}
.ContenedorNosotros h4 {
    font-family: "TitilliumWebBold";
    text-align: justify;
    color: #666;
}
.ContenedorNosotros p {
    font-family: "SourceSans3";
    text-align: justify;
    color: #666;
    white-space: pre-wrap;
}
.ContenedorNosotros li {
    font-family: "SourceSans3";
    text-align: justify;
    color: #666;
}
.firmaDirector {
    text-align: center !important;
}