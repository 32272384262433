.ContenidoSeminario {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    min-height: 600px;
}
.ContenidoSeminario h1 {
    font-family: "TitilliumWebBold";
    text-align: justify;
}
.tablaSeminarios {
    font-family: "TitilliumWebBold";
    text-align: justify;
    width: 100%;
    border-collapse: collapse;
}
.tablaSeminarios thead {
    background-color: #f2f2f2;
    
}
.tablaSeminarios th{
    border: 1px solid #ececec;
    color: #666;
    padding: 8px;
    text-align: left;
    font-family: "SourceSans3";
    border-bottom: 1px solid black !important;
}
.tablaSeminarios td {
    border: 1px solid #ececec;
    color: #666;
    padding: 8px;
    text-align: left;
    font-family: "SourceSans3";
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 999;
}

.modal-noticias {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 40%;
    height: 80%;
    padding: 20px;
    border-radius: 4px;
    overflow-y: auto;
}

.modal-noticias img {
    width: 80%;
    float: left;
    display: block;
    align-self: center;
    box-shadow: 0px 0px 4px black;
    border-radius: 1vh;
    margin-bottom: 10px;
}

.modal-noticias h1 {

    font-family: "TitilliumWebBold";
    font-size: large;
    text-align: center;
    color: black;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
}

.modal-noticias h2 {
    font-family: "TitilliumWebBold";
    font-size: medium;
    text-align: center;
    color: #199dec;
    margin-top: 0px;
    margin-bottom: 10px;
}

.textoMiembro {
    display: flex;
    flex-direction: column;
}

.modal-noticias i {
    font-size: medium;
    text-align: justify;
    padding: 10px;
    align-self: center;
    color: black;
    font-family: "SourceSans3";
    white-space: pre-wrap;
}

.modal-noticias p {
    font-size: medium;
    text-align: justify;
    margin: 0;
    padding: 10px;
    color: black;
    font-family: "SourceSans3";
    white-space: pre-wrap;
}

.modal-enter {
    opacity: 0;
}

.modal-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.modal-exit {
    opacity: 1;
}

.modal-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.tituloSeminario {
    color: #199dec !important;
    cursor: pointer;
}

.tituloSeminario:hover {
    text-decoration: underline;
}