.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 900;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.navbar-logo-menu {
    display: flex;
    justify-content: space-around;
    height: 150px;
    padding: 0px;
    margin: 0px;
}

.navbar-menu {
    list-style: none;
    display: flex;
    position: relative;
    
    margin: 0;
    justify-content: center;
    font-family: "TitilliumWebBold";
    font-weight: 600;
    font-size: 14px;
}
.navbar-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1rem;
    color: black;
    cursor: pointer;
    text-decoration: none;
}
.navbar-item:hover {
    background-color: #f0f0f0;
    color: #199dec;
}
/* Configuración del menú para la animación */
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0px 0px 4px black;
    border-top: 3px solid #199dec;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0;
    width: 200px;
    z-index: 1000;
    opacity: 0;
    /* Oculto por opacidad */
    visibility: hidden;
    /* Oculto pero sin eliminar del flujo del documento */
    transform: translateY(-20px);
    /* Desplazado ligeramente hacia arriba */
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
}

/* Mostrar el menú con animación */
.navbar-item:hover .dropdown-menu,
.dropdown-menu:hover {
    opacity: 1;
    /* Visibilidad total */
    visibility: visible;
    /* Hacer visible el menú */
    transform: translateY(0);
    /* Volver a su posición original */
}

.dropdown-menu a {
    padding: 0.5rem 1rem;
}

.dropdown-menu li a {
    color: black !important;
    text-decoration: none;
    display: block;
}

.dropdown-menu li a:hover {
    background-color: #f0f0f0;
    color: #199dec !important;
}