.Footer {
    background-color: #0C0C0C;
}
.footerSuperior {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}
.footerSuperior img{
    height: 100px;
}
.footerInferiror {
    width: 100%;
    height: 50%;
    padding-top: 20px;
    padding-bottom: 20px;
}
.footerInferiror p {
    color: gray;
    margin: 0px;
    text-align: left;
    padding-left: 20px;
    font-family: "SourceSans3";
    font-size: 14px;
}