.Actualidad {
    margin-bottom: 50px;
}
.tituloActualidad {
    font-family: "TitilliumWebBold";
    font-size: 18px;
    font-weight: 800;
    text-align: left;
    border-top: 3px solid black;
    border-bottom: 1px solid rgb(184, 184, 184);
    padding: 15px;
}
.centralPanelNoticias {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: 100px;
}

.contenedorNoticias {
    flex: 1 1 40%;
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 15px;
    margin: 5px;
    cursor: pointer;
}

.contenedorNoticias:hover {
    background-color: #9999992c;
}

.tituloNoticias {
    font-family: "TitilliumWebBold";
    font-size: large;
    text-align: center;
    color: black;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
}

.fechaNoticia {
    font-family: "TitilliumWebBold";
    font-size: medium;
    text-align: center;
    color: #199dec;
    margin-top: 0px;
    margin-bottom: 10px;
}

.imagenDescripcion {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    justify-content: center;
    align-self: center
}

.imagenNoticia {
    max-height: 200px;
    cursor: pointer;
    box-shadow: 0px 0px 4px black;
    border-radius: 1vh;
    align-self: center;
}

.descripcionNoticia {
    margin: 0px;
    text-align: justify;
    padding: 10px;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: medium;

}

.separadorSecundario {
    border: none;
    border-top: 1px solid #333;
    margin: 20px 0;
    align-self: normal;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 999;
}

.modal-noticias {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 40%;
    height: 80%;
    padding: 20px;
    border-radius: 4px;
    overflow-y: auto;
}

.modal-noticias img {
    width: 80%;
    float: left;
    display: block;
    align-self: center;
    box-shadow: 0px 0px 4px black;
    border-radius: 1vh;
    margin-bottom: 10px;
}

.modal-noticias h1 {

    font-family: "TitilliumWebBold";
    font-size: large;
    text-align: center;
    color: black;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
}

.modal-noticias h2 {
    font-family: "TitilliumWebBold";
    font-size: medium;
    text-align: center;
    color: #199dec;
    margin-top: 0px;
    margin-bottom: 10px;
}

.textoMiembro {
    display: flex;
    flex-direction: column;
}

.modal-noticias i {
    font-size: medium;
    text-align: justify;
    padding: 10px;
    align-self: center;
    color: black;
    font-family: "SourceSans3";
    white-space: pre-wrap;
}

.modal-noticias p {
    font-size: medium;
    text-align: justify;
    margin: 0;
    padding: 10px;
    color: black;
    font-family: "SourceSans3";
    white-space: pre-wrap;
}

.modal-enter {
    opacity: 0;
}

.modal-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.modal-exit {
    opacity: 1;
}

.modal-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}