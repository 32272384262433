body {
  margin: 0;
}
@font-face {
  font-family: "TitilliumWeb";
  src: url(./fonts/TitilliumWeb-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "TitilliumWebBold";
  src: url(./fonts/TitilliumWeb-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "SourceSans3";
  src: url(./fonts/SourceSans3-VariableFont_wght.ttf) format("truetype");
}