.ContenedorContacto {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    min-height: 600px;
}
.ContenedorContacto h1 {
    font-family: "TitilliumWebBold";
    text-align: justify;
}
.ContenedorContacto p {
    font-family: "SourceSans3";
    text-align: justify;
    color: #666;
    margin-bottom: 0px;
}
.contenedorFormulario {
    text-align: left;
}
.contenedorFormulario label {
    text-align: left;
    font-family: "SourceSans3";
    font-weight: 800;
    font-size: 12px;
    color: #939393;
    margin-bottom: 8px;
    letter-spacing: 1px;
}
.contenedorFormulario label::after{
    content: " *";
    color: red;
}
.contenedorFormulario input {
    height: 15px;
    padding: 10px 15px;
    color:#888; 
    border: 1px solid #888;
    font-family: "SourceSans3";
}
.contenedorFormulario input:focus {
    outline: 1px solid black;
}
.contenedorNombreApellidoEmail {
    max-width: 50%;
}
.contenedorNombreApellido {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}
.contenedorNombre {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1 1 calc(50.333% - 20px) !important;
}
.contenedorApellido {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1 1 calc(50.333% - 20px) !important;
}
.contenedorEmail {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.contenedorComentario {
    display: flex;
    flex-direction: column;
}
.contenedorComentario textarea{
    height: 100px;
    resize: none;
    padding: 10px 15px;
    color: #888;
    border: 1px solid #888;
    font-family: "SourceSans3";
    margin-bottom: 40px;
}
.contenedorComentario textarea:focus{
    outline: 1px solid black;
}
.botonEnviar {
    margin-top: 50px;
    font-family: "SourceSans3";
    background-color: black;
    color: white;
    padding: 10px 15px;
    transition: background-color 0.2s ease-in-out;
    border: none;
}
.botonEnviar:hover {
    background-color: #199dec;
}