.Actividades {
    margin-bottom: 50px;
}
.Actividades h1 {
    font-family: "TitilliumWebBold";
    font-size: 18px;
    font-weight: 800;
    text-align: left;
    border-top: 3px solid black;
    border-bottom: 1px solid rgb(184, 184, 184);
    padding: 15px;
}
.actividad {
    display: flex;
    margin-right: 5px;
    margin-left: 5px;
    position: relative;
}
.actividad img{
    width: 100%;
}
.tituiloActividad {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    align-self: flex-end;
}

.tituiloActividad a {
    color: white;
    font-size: 24px;
    font-family: "TitilliumWebBold";
    margin: 0;
    text-align: left;
    text-shadow: 0px 0px 4px black;
    cursor: pointer;
}

.tituiloActividad a:hover {
    text-decoration: underline;
}