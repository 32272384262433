.ContendorCuerpo {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    min-height: 600px;
}
.ContendorCuerpo h1{
    font-family: "TitilliumWebBold";
    text-align: justify;
}
.react-tabs__tab-list {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black !important;
}
.react-tabs__tab {
    border: 1px solid rgb(190, 190, 190) !important;
    border-bottom: 1px solid black !important;
    border-radius: 5px 5px 0 0;
    color: rgb(190, 190, 190);
    font-family: "TitilliumWeb";
}
.react-tabs__tab--selected {
    border: 1px solid black !important;
    border-bottom: none !important;
    font-family: "TitilliumWebBold";
}
.react-tabs__tab--selected::after {
    border: 1px solid black !important;
    display: none;
}
.contendorAcademicos {
    display: flex !important;
    flex-wrap: wrap !important;
}
.academico {
    display: flex;
    /* box-shadow: 0px 0px 4px black; */
    flex: 1 1 calc(30.333% - 20px) !important;
    flex-direction: column;
    margin: 10px;
    align-items: center;
    padding: 14px;
}
.imagenAcademico img{
    height: 100px;
    border-radius: 50%;
}
.academico h4 {
    font-family: "TitilliumWebBold";
    margin: 0px;
}
.academico p {
    font-family: "SourceSans3";
    margin-top: 0px;
    font-size: 14px;
}
.academico button {
    background-color: transparent;
    border: none;
    font-family: "SourceSans3";
    color: gray;
    font-size: 12px;
    cursor: pointer;
    padding: 5px 10px;
}
.academico button:hover{
    color: black;
}
.fondoModalAcademicos {
    display: flex;
    align-items: center;
    z-index: 2000;
    background: rgba(17, 17, 17, 0.85) !important;
}
.modalAcademicos {
    display: flex;
    max-width: 960px;
    padding: 0px !important;
    border-radius: 20px !important;
    transition: opacity 0.3s ease-in-out;
}
.contenedorImagenModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}
.contenedorImagenModal img{
    width: 100%;
}
.contenedorTextoModal {
    max-width: 50%;
    padding: 28px;
}
.contenedorTextoModal h4 {
    font-family: "TitilliumWebBold";
    margin: 0px;
}
.contenedorTextoModal p {
    font-family: "SourceSans3";
    margin-top: 0px;
    font-size: 14px;
}
.especialidad {
    font-size: 16px !important;
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-family: "SourceSans3";
    color: lightgray;
    transition: color 0.3s ease-in-out;
    padding: 0px 7px;
}
.close:hover {
    color: gray;
}
.correoAcademico {
    color: #199dec !important;
}
.modal-enter {
    opacity: 0;
}

.modal-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.modal-exit {
    opacity: 1;
}

.modal-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}