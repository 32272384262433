.ContenedorDocumentos {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    min-height: 600px;
}
.ContenedorDocumentos h1 {
    font-family: "TitilliumWebBold";
    text-align: justify;
}
.documento {
    display: flex;
    flex-direction: row;
}
.logoDocumento {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border: 1px solid rgba(255, 255, 255, 0.158);
    margin-right: 20px;
}
.logoDocumento img {
    width: 30px;
    height: 30px;
}
.textoDocumento {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.textoDocumento h4{
    margin: 0px;
    font-family: "TitilliumWebBold";
    text-align: left;
}
.textoDocumento a{
    margin: 0px;
    font-family: "SourceSans3";
    text-align: left;
    color: #199dec;
    cursor: pointer;
}
.textoDocumento a:hover {
    text-decoration: underline;
}