.Slider {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    margin-top: 150px;
}
.Slider h1 {
    font-size: 90;
}
.carousel-container {
    max-height: 600px;
}
.carousel-container img{
    width: 100%;
}
.carousel-container li{
    display: flex;
    justify-content: center;
    align-items: center;
}
.react-multiple-carousel__arrow {
    z-index: 0 !important;
}
.slide-item {
    position: relative;
}

.slide-item h1 {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: white;
    font-size: 4vw;
    font-family: "TitilliumWebBold";
    z-index: 10;
    margin: 0;
    margin-left: 4vw;

}

.slide-item img {
    width: 100%;
    height: auto;
    display: block;
}

.Slider {
    position: relative;
}