.ContenedorDashboard {
    display: flex;
    height: 100vh;
}
.sidebar {
    background-image: linear-gradient(90deg, #53b4ec, #047abe);
    background-color: #199dec;
    height: 100vh;
    width: 300px;
    min-width: 300px;
    overflow-y: auto;
    scrollbar-width: none;
}
.sidebar h1 {
    font-family: "TitilliumWebBold";
    color: white;
    text-align: left;
    margin: 0px;
    padding: 10px 20px;
    font-size: 30px;
    cursor: pointer;
}
.sidebar h1:hover{
    background-color: rgba(255, 255, 255, 0.2);
}
.Collapsible__trigger {
    display: block;
    font-size: 18px;
    text-align: left;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
}

.Collapsible__trigger:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.Collapsible {
    font-family: "SourceSans3";
    width: 100%;
    cursor: pointer;
    color: white;
}

.collapsibleInterior {
    display: block;
        font-size: 14px;
        text-align: left;
        border: none;
        cursor: pointer;
        padding: 10px 40px;
}

.Collapsible button {
    text-align: center;
    width: 100%;
    background: none;
    border: none;
    font-family: "SourceSans3";
    cursor: pointer;
    color: white;
    text-align: left;
    padding: 10px 40px;
    font-size: 14px;
}
.Collapsible button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    text-decoration: underline;
}
.cuerpoDashboard {
    display: block;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
}
.topbar {
    background-image: linear-gradient(-90deg, #53b4ec, #047abe);
    font-family: "TitilliumWebBold";
    color: white;
    text-align: left;
    margin: 0px;
    padding: 5px;
    font-size: 30px;
}