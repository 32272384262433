.Informacion {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0px;
    gap: 20px;
    margin-bottom: 50px;
}
.contenedorInfo {
    width: 250px;
    padding: 25px;
}
.contenedorInfo h1{
    font-family: "SourceSans3";
    font-size: 22px;
    color: white;
    margin: 0px;
    text-align: center;
}
.contenedorInfo p{
    font-family: "SourceSans3";
    font-size: 16px;
    color: white;
    text-align: center;
}