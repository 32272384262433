.fondoLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(90deg, #5cafe2, #047abe);
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.panelLogin {
    font-family: "SourceSans3";
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
}

.panelLogin img {
    width: 50%;
}

.panelLogin h2 {
    font-family: "TitilliumWebBold";
    text-align: center;
    margin-top: 0;
}

.panelLogin form {
    display: flex;
    flex-direction: column;
}

.panelLogin label {
    margin-bottom: 15px;
}

.panelLogin input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
    font-family: "SourceSans3";
}

.panelLogin button {
    padding: 10px;
    background-image: linear-gradient(90deg, #5cafe2, #047abe);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: "SourceSans3";
}

.panelLogin button:hover {
    font-weight: 600;

}