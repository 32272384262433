.ContenedorCEC {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    min-height: 600px;
}

.ContenedorCEC h1 {
    font-family: "TitilliumWebBold";
    text-align: justify;

}

.ContenedorCEC p {
    font-family: "SourceSans3";
    text-align: justify;
    color: #666;
    white-space: pre-wrap;
}

.ContenedorCEC h4 {
    font-family: "SourceSans3";
    text-align: justify;
}
.contenedorDirectorioPrograma {
    display: flex;
    gap: 20px;
}
.planificacion {
    margin-top: 50px;
    text-align: left;
}
.planificacion a {
    
    font-family: "SourceSans3";
    background-color: black;
    color: white;
    padding: 10px 15px;
    margin-top: 50px;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}
.planificacion a:hover {
    background-color: #199dec;
}
.planificacion button {
    font-family: "SourceSans3";
    background-color: black;
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: left;
    cursor: pointer;
    font-size: medium;
    transition: background-color 0.2s ease-in-out;
    text-decoration: none;
    width: fit-content;
}
.planificacion button:hover {
    background-color: #199dec;
}
.miembrosCEC {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;

}
.imgCEC {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
}
.miembrosCEC p {
    text-align: center;
}