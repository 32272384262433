.ContenedorEmpleos {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    min-height: 600px;
}
.ContenedorEmpleos h1 {
    font-family: "TitilliumWebBold";
    text-align: justify;
}
.ContenedorEmpleos p {
    font-family: "SourceSans3";
    text-align: justify;
    color: #666;
}
.sinOfertas {
    height: 100px;
    background-color: rgb(231, 76, 60);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "SourceSans3";
    font-size: 33px;
}